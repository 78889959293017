import React from 'react';
import './styles.css';
import { Dialog, Typography } from '@mui/material';

const DialogResult = ({ open, handleClose, result, text }) => {

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <div className={`result ${result ? 'success' : 'failure'}`}>
                <Typography variant='h4'  color='white'>{text}</Typography>
            </div>
        </Dialog>
    );
};

export default DialogResult;
