import React from "react"
import { Navigate } from "react-router-dom";
import { useAppContext } from '../AppProvider';

const PublicRoute = ({ children }) => {

    const { token } = useAppContext();

    return (token)
        ? <Navigate to="/access"/>
        : children;
}

export default PublicRoute;
