import React, { useEffect, useState } from 'react';
import { Screen, Loader } from '../../../components';
import Grid from '@mui/material/Unstable_Grid2';
import { Card, ToggleButton, ToggleButtonGroup } from '@mui/material';
import TableList from './tableList';
import { GET_NO_AUTH } from "../../../api";
import { VictoryPie } from "victory";
import './styles.css';

const StudentsListScreen = () => {
    const [students, setStudents] = useState([]);
    const [data, setData] = useState(null);
    const [event, setEvent] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const labelsMap = {
        'dinners_in': 'Cenas Dentro',
        'dinners_out': 'Cenas Fuera',
        'afters_in': 'After Dentro',
        'afters_out': 'After Fuera',
    };

    const onSuccessGetStudents = response => setStudents(response.data);
    const onFailureGetStudents = error => {};
    const onSuccessGetGuests = response => {
        setStudents([...students, ...response.data]);
    };

    const onFailureGetGuests = error => {};

    const onSuccessGetData = response => setData(response.data);
    const onFailureGetData = error => console.log(error);

    const handleChangeToggle = (event, newAction) => setEvent(newAction);

    const transformDataForPieChart = (data) => {
        return Object.keys(data).map((key) => {
            return { x: labelsMap[key] || key, y: data[key] };
        });
    };

    const PieChartContainer = ({ children }) => (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
            {children}
        </div>
    );

    const PieChart = () => {
        const pieData = transformDataForPieChart(data);
        return (
            <PieChartContainer>
                <VictoryPie
                    data={pieData}
                    labels={({ datum }) => `${datum.x}: ${datum.y}`}
                />
            </PieChartContainer>
        );
    };

    const DataTable = ({ data }) => (
        <table>
            <thead>
            <tr>
                <th>Categoría</th>
                <th>Cantidad</th>
            </tr>
            </thead>
            <tbody>
            {Object.keys(data).map(key => (
                <tr key={key}>
                    <td>{labelsMap[key] || key}</td>
                    <td>{data[key]}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );

    useEffect(() => {
        setIsLoading(true);
        GET_NO_AUTH(
            `events/alumnus/get_entries?event=${event}`,
            onSuccessGetData,
            onFailureGetData,
            () => setIsLoading(false),
        );

        GET_NO_AUTH(
            `events/alumnus?event=${event}`,
            onSuccessGetStudents,
            onFailureGetStudents,
        );

        GET_NO_AUTH(
            `events/alumnus/guests`,
            onSuccessGetGuests,
            onFailureGetGuests,
            () => setIsLoading(false),
        );
    }, [event]);

    const Nav = () => (
        <Grid xs={12} spacing={2}>
            <ToggleButtonGroup
                color="primary"
                value={event}
                exclusive
                onChange={handleChangeToggle}
                aria-label="Platform"
                fullWidth
            >
                <ToggleButton value="1">North</ToggleButton>
                <ToggleButton value="2">UP</ToggleButton>
            </ToggleButtonGroup>
        </Grid>
    );

    if (isLoading) {
        return (
            <Screen>
                <Nav />
                <Loader />
            </Screen>
        );
    }

    return (
        <Screen>
            <Grid xs={12} spacing={2}>
                {
                    data && (
                        <>
                            <PieChart />
                            <DataTable data={data} />
                        </>
                    )
                }
                <TableList students={students} />
            </Grid>
        </Screen>
    );
};

export default StudentsListScreen;
