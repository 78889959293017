import React from "react"
import { Navigate } from "react-router-dom";
import { useAppContext } from '../AppProvider';

const PrivateRoute = ({ children }) => {

    const { token } = useAppContext();

    return token
        ? children
        : <Navigate to="/" />;
}

export default PrivateRoute;
