import React, { createContext, useContext, useReducer } from 'react';

const AppContext = createContext();

const initialState = {
    user: null,
    token: null,
    admin: false,
};

const reducer = (state, action) => {
    const { type, value } = action;
    switch (type) {
        case 'SET_SESSION': {
            return {
                ...state,
                user: value,
                token: value.auth_token.key,
            };
        }
        case 'SET_ADMIN': {
            return {
                ...state,
                admin: value
            }
        }
        default: { return state; }
    }
};

export const useAppContext = () => {
    return useContext(AppContext);
};

export const AppProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AppContext.Provider>
    );

};
