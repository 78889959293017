import './App.css';
import { AppProvider } from './AppProvider';
import AppRouter from "./navigation";

function App() {

  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
}

export default App;
