import React from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

const Screen = ({ children }) => {
    return (
        <Box sx={{ flexGrow: 1 }} p={2}>
            <Grid container spacing={2}>
                {children}
            </Grid>
        </Box>
    );
};

export default Screen;
