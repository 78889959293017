import { object, string } from 'yup';

const errors = object({
    user: string('Escribe un usuario valido')
        .required('Campo requerido')
        .typeError('Escribe un usuario valido'),
    password: string('Escribe una contraseña')
        .required('Campo requerido')
        .typeError('Escribe una contraseña'),
});

export default errors;
