import React, { useRef, useState, useEffect } from 'react';
import { Screen } from '../../../components';
import { Paper, TextField, Typography, ToggleButtonGroup, ToggleButton, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DialogResult from "./dialogResult";
import { POST_NO_AUTH } from '../../../api';
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import successMP3 from '../../../success.mp3';
import errorMP3 from '../../../error.mp3';
import ScannerCam from "./ScannerCam";
import { useAppContext } from '../../../AppProvider';

const AccessScreen = () => {

    const { admin } = useAppContext();

    const [openDialogResult, setOpenDialogResult] = useState(false);
    const [code, setCode] = useState('');
    const [result, setResult] = useState(false);
    const [action, setAction] = useState('in');
    const [text, setText] = useState(null);
    const [deviceDefault, setDeviceDefault] = useState('scanner');
    const [checkingCode, setCheckingCode] = useState(false);
    const [videoRecord, setVideoRecord] = useState(false);

    const audio = new Audio(successMP3);
    const audioError = new Audio(errorMP3);

    const inputRef = useRef();

    const handleSetCode = data => setCode(data);

    const handleCloseDialogResult = () => setOpenDialogResult(false);
    const handleOpenDialogResult = () => {
        setOpenDialogResult(true);
        setCode('');
        if(deviceDefault === 'scanner') {
            inputRef.current.focus();
        }
    }

    useEffect(() => {
        if(code.length === 8) {
            validCode()
        }
    }, [code])

    const onSuccessValidCode = response => {
        audio.play();
        setResult(true)
        setText("Acceso concedido");
    };
    const onFailureValidCode = error => {
        audioError.play()
        setResult(false);
        try {
            setText(error.error.message);
        } catch (e) {
            setText(error.response.data.message);
        }
    };
    const onDoneValidCode = () => {
        handleOpenDialogResult();
        setTimeout(() => {
            setCode('');
            handleCloseDialogResult();
            setCheckingCode(false);
        }, 2000);
    }

    const validCode = () => {
        if (!checkingCode) {
            setCheckingCode(true);
            POST_NO_AUTH(
                'tickets/view/',
                {code, action, user: 'adm_PGS'},
                onSuccessValidCode,
                onFailureValidCode,
                onDoneValidCode
            );
        }
    };

    const handleChangeToggle = (event, newAction) => {
        setAction(newAction);
        if(deviceDefault === 'scanner') {
            inputRef.current.focus();
        }
    }

    const changeScanMode = () => {
        setVideoRecord(deviceDefault === 'scanner');
        setTimeout(() => {
            setDeviceDefault(deviceDefault === 'scanner' ? 'cam' : 'scanner');
        }, 200);
    };

    return (
        <Screen>
            <Grid xs={12} md={6} mdOffset={3}>
                <Paper>
                    <Grid container p={2}>
                        <Grid xs={12}>
                            <Typography variant="h6" sx={{
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold'
                                }}
                            >
                                Escanea el código de acceso
                            </Typography>
                        </Grid>
                        <Grid xs={12} mt={1}>
                            {
                                deviceDefault === 'cam' ? (
                                    <ScannerCam
                                        handleSetCode={handleSetCode}
                                        scanning={videoRecord}
                                    />
                                ) : (
                                    <TextField
                                        label='Código'
                                        inputRef={inputRef}
                                        name="code"
                                        variant="outlined"
                                        value={code}
                                        onChange={e  => setCode(e.target.value)}
                                        fullWidth
                                        inputProps={{ maxLength: 8 }}
                                        onKeyDown={e => {
                                            if(e.keyCode === 13){
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                )
                            }
                        </Grid>
                        <Grid xs={12}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Button
                                    variant="text"
                                    onClick={changeScanMode}
                                >
                                    <FontAwesomeIcon icon={faCamera} />
                                </Button>
                            </Grid>
                        </Grid>
                        {
                            admin && (
                                <Grid xs={12} mt={2}>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={action}
                                        exclusive
                                        onChange={handleChangeToggle}
                                        aria-label="Platform"
                                        fullWidth
                                    >
                                        <ToggleButton value="in">Entradas</ToggleButton>
                                        <ToggleButton value="out">Salidas</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            )
                        }
                    </Grid>
                </Paper>
                {
                    admin && (
                        <Grid xs={12} mt={2}>
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Button variant="text">
                                    <Link to='/listaccess'>
                                        VER LISTADO
                                    </Link>
                                </Button>
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
            <DialogResult
                open={openDialogResult}
                handleClose={handleCloseDialogResult}
                result={result}
                text={text}
            />
        </Screen>
    );

};

export default AccessScreen;
