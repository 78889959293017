import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import PublicRoute from './publicRoute';
import PrivateRoute from "./privateRoute";
import * as screens from '../screens';
import {useAppContext} from "../AppProvider";

const AppRouter = () => {

    const { dispatch } = useAppContext();

    useEffect(() => {
        const session = localStorage.getItem('login');
        const admin = localStorage.getItem('admin');
        if (session) {
            dispatch({
                type: 'SET_SESSION',
                value: JSON.parse(session)
            });
            dispatch({
                type: 'SET_ADMIN',
                value: admin
            })
        }
    }, []);

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PublicRoute>
                        <screens.LoginScreen />
                    </PublicRoute>
                }
            />
            <Route
                path="/access"
                element={
                    <PrivateRoute>
                        <screens.AccessScreen />
                    </PrivateRoute>
                }
            />
            <Route
                path="/listaccess"
                element={
                    <PrivateRoute>
                        <screens.StudentsListScreen />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
};

export default AppRouter;
