import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faChampagneGlasses } from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Paper from "@mui/material/Paper";

const TableList = ({ students }) => {

    const getIconType = type => {
        let color = '#723131';
        let icon = faUtensils;
        if (type === 2) {
            color = '#b7af00';
            icon = faChampagneGlasses;
        }

        return (
            <FontAwesomeIcon
                icon={icon}
                color={color}
            />
        )
    }

    // Función para ordenar por name, luego por table y luego por ticket_number
    const sortedStudents = students.sort((a, b) => {
        if (a.name !== b.name) {
            return a.name.localeCompare(b.name);
        }
        if (a.table !== b.table) {
            return a.table.localeCompare(b.table);
        }
        return a.ticket_number - b.ticket_number;
    });

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell align="right">Código</TableCell>
                        <TableCell align="right">Estatus</TableCell>
                        <TableCell align="right">Mesa</TableCell>
                        <TableCell align="right">Número de Ticket</TableCell>
                        <TableCell align="right">Hora de Validación</TableCell>
                        <TableCell align="right">Correo Electrónico</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedStudents.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <Typography variant='h6'>
                                    {row.name}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">{row.code}</TableCell>
                            <TableCell align="right">
                                {row.block ? 'Dentro' : 'Fuera'}
                            </TableCell>
                            <TableCell align="right">{row.table}</TableCell>
                            <TableCell align="right">{row.ticket_number}</TableCell>
                            <TableCell align="right">{row.block ? new Date(row.validation_time).toLocaleString() : ''}</TableCell>
                            <TableCell align="right">{row.email}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableList;
