import React from 'react';
import { Screen } from '../../../components';
import LoginForm from "./forms/login";
import Grid from '@mui/material/Unstable_Grid2';

const LoginScreen = () => {

    return (
        <Screen>
            <Grid xs={12} md={6} mdOffset={3}>
                <Grid display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <LoginForm />
                </Grid>
            </Grid>
        </Screen>
    );
};

export default LoginScreen;
