import React, {useState} from 'react';
import { Button, TextField } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import initialValues from './initialValues';
import errors from './errors';
import Grid from "@mui/material/Unstable_Grid2";
import { useAppContext } from '../../../../../AppProvider';

const LoginForm = () => {

    const { dispatch } = useAppContext();
    const [admin, setAdmin] = useState(false);

    const users = {
        'gue_001': 'GUE@001',
        'gue_002': 'GUE@002'
    }

    const admins = {
        'adm_001': 'ADM@001',
        'adm_002': 'ADM@002'
    }

    const loginHandle = values => {
        if (users[values.user] === values.password || admins[values.user] === values.password) {
            const admin = admins[values.user] === values.password;
            localStorage.setItem('admin', admin);
            dispatch({
                type: 'SET_ADMIN',
                value: admin
            })
            return true;
        }
        return false;
    };


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={errors}
            onSubmit={values => {
                const isLogin = loginHandle(values);
                if (isLogin) {
                    const session = {
                        auth_token: {
                            key: '123123',
                        }
                    }
                    localStorage.setItem('login', JSON.stringify(session));
                    dispatch({
                        type: 'SET_SESSION',
                        value: session
                    })
                }
            }}
        >
            <Form>
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Field
                            as={TextField}
                            label='Usuario'
                            name="user"
                            variant="outlined"
                            error={errors.user}
                            helperText={<ErrorMessage name="user" />}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Field
                            as={TextField}
                            label="Contraseña"
                            name="password"
                            type="password"
                            variant="outlined"
                            error={errors.password}
                            helperText={<ErrorMessage name="password" />}
                            fullWidth
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                        >
                            Iniciar sesión
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
};

export default LoginForm;
