import React, { useState, useEffect } from "react";

import {
    BrowserQRCodeReader,
    BrowserCodeReader
} from "@zxing/browser";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRepeat} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@mui/material";
import './styles.css';

const ScannerCam = ({ handleSetCode, scanning }) => {

    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const [listDevices, setListDevices] = useState([]);
    const [indexDevices, setIndexDevices] = useState(0);

    const codeReader = new BrowserQRCodeReader();

    // Control del escaneo continuo
    let controls = null;

    const setupDevices = videoInputDevices => setSelectedDeviceId(videoInputDevices[indexDevices]?.deviceId);

    const changeDevices = () => {
        const index = indexDevices === 0 ? 1 : 0;
        setSelectedDeviceId(listDevices[index]?.deviceId);
        setIndexDevices(index);
    }

    const stopCamera = () => {
        if(controls && selectedDeviceId){
            controls.stop();
        }
    }

    useEffect(() => {
        BrowserCodeReader
            .listVideoInputDevices()
            .then(videoInputDevices => {
                if(videoInputDevices){
                    setListDevices(videoInputDevices);
                    setupDevices(videoInputDevices);
                }
            })
            .catch(err => {
                console.error(err);
            });
    }, [])
    

    useEffect(() => {
        console.log('<<<< scanning');
        console.log(scanning);
        if(scanning){
            decodeContinuously(selectedDeviceId);
        } else {
            stopCamera();
        }
    }, [scanning]);

    const resetClick = () => {
        codeReader.reset();
    }

    const decodeContinuously = async selectedDeviceId => {

        const previewElem = document.querySelector('#video');

        controls = await codeReader.decodeFromVideoDevice(
            selectedDeviceId,
            previewElem,
            (result, error, controls) => {
                if (result) {
                    handleSetCode(result.text);
                }

                if (error) {

                }
            }
        );
    }

    useEffect(deviceId => {
        stopCamera();
        decodeContinuously(selectedDeviceId);
    }, [selectedDeviceId]);
    

    return (
        <div className='center-button'>
            <video id="video" width="100%" height="500" />
            {
                listDevices.length > 0 && (
                    <Button
                        variant="text"
                        onClick={changeDevices}
                    >
                        <FontAwesomeIcon icon={faRepeat} />
                    </Button>
                )
            }
        </div>
    )

};

export default ScannerCam;
