import axios from 'axios';
import baseURL, { DOMAIN_GRADUATE } from './conf';

const api = axios.create({ baseURL });
const api2 = axios.create({ DOMAIN_GRADUATE });
api.defaults.timeout = 100000;
api2.defaults.timeout = 100000;


const defaultError = {
    error: {
        success: false,
        message: 'Ocurrió un error',
        title: 'Ocurrió un error',
        status: 400,
    },
};

const handleError = (error, onError) => {
    if (error.response.status !== 500) {
        const { response: { status } } = error;
        onError(error);
    } else {
        onError(defaultError);
    }
};

export const POST_NO_AUTH = (path, data, onSuccess, onError, onDone = null, origen = '00') => {
    (origen === '00' ? api : api2)
        .post(path, data)
        .then(response => onSuccess(response))
        .catch(error => handleError(error, onError))
        .then(onDone);
};

export const GET_NO_AUTH = async (path, onSuccess, onError, onDone = null, origen = '00') => {
    (origen === '00' ? api : api2)
        .get(path)
        .then(response => onSuccess(response))
        .catch(error => handleError(error, onError))
        .then(onDone);
};
