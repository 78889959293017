import React from 'react';
import {Box, Grid, CircularProgress} from '@mui/material';
import { loadResources } from 'i18next';


const Loader = () => {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<Grid
				container
				item
				xs={12}
				sx={{
				direction: 'flex',
				justifyContent: 'center',
				alignItem: 'center',
				alignSelf: 'center'
			}}>
				<CircularProgress size={60} color={'warning'} />
			</Grid>
		</Box>
	);
}

export default Loader;